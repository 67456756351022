<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title
          >{{ isUpdate ? "Modificar usuario" : "Crear usuario" }}
        </v-card-title>

        <v-card-text class="mt-8">
          <v-form @submit.prevent="save" ref="formNewUser" autocomplete="off">
            <v-row>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-select
                  v-model="profileSelected"
                  :items="profiles"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Tipo de usuario"
                ></v-select>
              </v-col>
              <v-col
                v-if="profileSelected === 4"
                lg="6"
                class="col-12 my-0 py-0"
              >
                <v-select
                  v-model="id_userN1"
                  :items="userN1"
                  item-text="email"
                  item-value="id"
                  outlined
                  label="Usuario N1"
                ></v-select>
              </v-col>
              <!-- <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="id_usuario"
                  outlined
                  label="ID USUARIO"
                  :error-messages="errores.id_usuario"
                  value=""
                  autocomplete="off"
                ></v-text-field>
              </v-col> -->
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="name"
                  outlined
                  label="Nombre (*)"
                  maxlength="100"
                  :error-messages="errores.name"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="email"
                  outlined
                  label="Email (*)"
                  maxlength="100"
                  :error-messages="errores.email"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="ruc_name"
                  outlined
                  label="Razón social (*)"
                  maxlength="100"
                  :error-messages="errores.ruc_name"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="ruc"
                  :error-messages="errores.ruc"
                  outlined
                  label="RUC (*)"
                  maxlength="20"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="adress"
                  outlined
                  :error-messages="errores.adress"
                  label="Dirección (*)"
                  maxlength="100"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <v-text-field
                  v-model="phone"
                  outlined
                  :error-messages="errores.phone"
                  label="Teléfono (*)"
                  maxlength="15"
                ></v-text-field>
              </v-col>
              <v-col lg="6" class="col-12 my-0 py-0">
                <!-- <v-text-field
                  v-model="password"
                  outlined
                  :error-messages="errores.password"
                  label="Contraseña"
                ></v-text-field> -->
                <v-text-field
                  v-if="!update"
                  v-model="password"
                  :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  label="Contraseña (*)"
                  maxlength="100"
                  :error-messages="errores.password"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col lg="12" class="col-12 my-0 py-0">
                <v-textarea
                  v-model="commit"
                  :error-messages="errores.commit"
                  label="Comentarios"
                  maxlength="250"
                  counter
                  outlined
                />
              </v-col>
            </v-row>
            <v-col>
              <v-row>
                <v-checkbox
                  v-if="deviceClasses.agua"
                  v-model="agua"
                  color="red"
                  label="Agua"
                ></v-checkbox>
                <v-checkbox
                  v-if="deviceClasses.gas"
                  v-model="gas"
                  color="red"
                  label="Gas"
                ></v-checkbox>
                <v-checkbox
                  v-if="deviceClasses.energia"
                  v-model="energia"
                  color="red"
                  label="Energía"
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-if="hasTelemetry"
                  v-model="has_telemetry"
                  color="indigo"
                  label="Telemetría"
                ></v-checkbox>
                <v-checkbox
                  v-if="hasTelecontrol"
                  v-model="has_telecontrol"
                  color="indigo"
                  label="Telecontrol"
                ></v-checkbox>
              </v-row>
              <v-row v-if="isSuperAdmin">
                <v-switch
                  v-model="isBrandBlank"
                  label="Es marca blanca"
                  color="red"
                  hide-details
                ></v-switch>
              </v-row>
              <v-row class="mt-8" v-if="isBrandBlank">
                <v-col lg="12" class="my-0">
                  <DialogUploadComponent
                    ref="subirImagen"
                    :logoLink="downloadURL"
                    :update="update"
                    @onUploadLogo="onUploadLogo"
                  />
                </v-col>
                <v-col lg="12" class="my-0">
                  <v-text-field
                    v-model="footer"
                    outlined
                    label="Pie de página"
                  ></v-text-field>
                </v-col>
                <v-col lg="12" class="my-0 py-0">
                  <v-text-field
                    v-model="brandBlank"
                    outlined
                    label="Color principal"
                  ></v-text-field>
                </v-col>
                <v-col lg="6" class="col-12 my-0 py-0">
                  <v-color-picker
                    v-model="brandBlank"
                    class="ma-2"
                    mode="hexa"
                    hide-inputs
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click="close()" color="secondary" dark> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isLoading"
            @click="save()"
            type="submit"
            color="primary"
            dark
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";
import DialogUploadComponent from "./dialogUploadComponent.vue";

export default {
  components: {
    DialogUploadComponent,
  },
  data() {
    return {
      downloadURL: null,
      imageBlob: null,
      color: "",
      userId: 0,
      user: {},
      showPassword: false,
      isBrandBlank: false,
      dialog: false,
      update: false,
      footer: null,
      logoLink: null,
      logo: null,
      brandBlank: "#00BFEF",
      profileSelected: "",
      password: "",
      name: null,
      email: null,
      ruc_name: null,
      ruc: null,
      adress: null,
      phone: null,
      commit: null,
      // id_usuario: "",
      agua: false,
      gas: false,
      energia: false,
      has_telemetry: false,
      has_telecontrol: false,
      isLoading: false,
      errores: {
        password: null,
        name: null,
        email: null,
        ruc_name: null,
        ruc: null,
        adress: null,
        phone: null,
        commit: null,
      },
      profiles: [],
      userN1: [],
      deviceClasses: {
        agua: false,
        energia: false,
        gas: false,
      },
      id_userN1: null,
    };
  },
  computed: {
    isUpdate() {
      return this.update;
    },
    isSuperAdmin() {
      return this.$cookies.get("isSuperAdmin") === "true" ? true : false;
    },
    hasTelemetry() {
      return this.$cookies.get("user")?.has_telemetry;
    },
    hasTelecontrol() {
      return this.$cookies.get("user")?.has_telecontrol;
    },
  },
  created() {
    // this.getProfile();
    // this.getUserN1();
    this.getDeviceClass();
  },
  mounted() {
    this.$cookies.get("user");
    this.getProfile();
    this.getUserN1();
  },
  methods: {
    cambiarColor() {
      this.brandBlank = this.color;
    },
    open() {
      this.dialog = true;
    },
    openUpdate(user) {
      this.update = true;
      this.dialog = true;
      this.userId = user.id;
      this.getUserById();
    },
    close() {
      this.isLoading = false;
      this.dialog = false;
      this.update = false;
      this.$refs.formNewUser.resetValidation();
      this.$refs.formNewUser.reset();
    },
    save() {
      if (this.$refs.formNewUser.validate()) {
        this.isLoading = true;
        this.errores = {
          password: null,
          name: null,
          email: null,
          ruc_name: null,
          ruc: null,
          adress: null,
          phone: null,
          commit: null,
          // id_usuario: null,
        };
        const newUser = {
          name: this.name,
          email: this.email,
          ruc_name: this.ruc_name,
          ruc: this.ruc,
          adress: this.adress,
          phone: this.phone,
          commit: this.commit,
          agua: this.agua,
          gas: this.gas,
          energia: this.energia,
          has_telemetry: this.has_telemetry,
          has_telecontrol: this.has_telecontrol,
          password: this.password,
          profileSelected: this.profileSelected,
          id_userN1: this.id_userN1,
          logo: this.downloadURL,
          footer: this.footer,
          isBrandBlank: this.isBrandBlank,
          color: this.brandBlank,
        };
        if (!this.update) {
          BackendApi.post("/user/user", newUser)
            .then((response) => {
              if (response.data.success) {
                this.$emit("newUser");
                this.close();
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
              if (error.response.data.errors) {
                this.errores[error.response.data.errors[0]?.param] =
                  error.response.data.errors[0]?.msg;
              } else {
                this.errores.name = error.response.data?.msg;
              }
            });
        } else {
          BackendApi.put("/user/user/" + this.userId, newUser)
            .then((response) => {
              if (response.data.success) {
                this.$emit("newUser");
                this.close();
              }
            })
            .catch((error) => {
              this.isLoading = false;

              if (error.response.data.errors) {
                this.errores[error.response.data.errors[0]?.param] =
                  error.response.data.errors[0]?.msg;
              } else {
                this.errores.name = error.response.data?.msg;
              }
            });
        }
      }
    },
    getUserById() {
      const payload = {
        user_id: this.userId,
      };
      BackendApi.post("/user/userById", payload)
        .then((response) => {
          if (response.data.success) {
            // this.id_usuario = response.data.data.id_usuario;
            this.name = response.data.data.name;
            this.email = response.data.data.email;
            this.ruc_name = response.data.data.ruc_name;
            this.ruc = response.data.data.ruc;
            this.adress = response.data.data.adress;
            this.phone = response.data.data.phone;
            this.commit = response.data.data.commit;
            this.brandBlank = response.data.data.brand_blank.color;
            this.footer = response.data.data.brand_blank.footer;
            this.downloadURL = response.data.data.brand_blank.logo;
            if (response.data.data.brandBlankId == 1) {
              this.isBrandBlank = false;
            } else {
              this.isBrandBlank = true;
            }
            if (response.data.data.device_classes.length > 0) {
              response.data.data.device_classes.forEach((element, index) => {
                if (response.data.data.device_classes[index].name === "agua") {
                  this.agua = true;
                }
                if (response.data.data.device_classes[index].name === "gas") {
                  this.gas = true;
                }
                if (
                  response.data.data.device_classes[index].name === "energía"
                ) {
                  this.energia = true;
                }
              });
            }
            this.has_telemetry = response.data.data.has_telemetry;
            this.has_telecontrol = response.data.data.has_telecontrol;
            // if (response.data.data.brand_blank) {
            //   this.isBrandBlank = true;
            //   this.logo = response.data.data.brand_blank.logo;
            //   this.brandBlank.hex = response.data.data.brand_blank.color;
            //   this.footer = response.data.data.brand_blank.footer;
            // }
            // this.brandBlank = response.data.data.brandBlank;
            // this.password = response.data.data.password;
            this.user = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response.data.errors) {
            this.errores[error.response.data.errors[0]?.param] =
              error.response.data.errors[0]?.msg;
          } else {
            this.errores.name = error.response.data?.msg;
          }
        });
    },
    getProfile() {
      BackendApi.get("/user/profilesToAssign")
        .then((response) => {
          if (response.data.success) {
            // this.id_usuario = response.data.data.id_usuario;

            this.profiles = response.data.data;
            let temporal = this.profiles;
            this.profileSelected = this.profiles[0].id;
            if (this.profileSelected == 3) {
              this.profiles = [];
              this.profiles.push(temporal[0]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserN1() {
      BackendApi.get("/user/usersN1")
        .then((response) => {
          if (response.data.success) {
            this.userN1 = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeviceClass() {
      BackendApi.get("/deviceClass/deviceClassById")
        .then((response) => {
          if (response.data.success) {
            response.data.data.device_classes.forEach((element) => {
              if (element.name === "agua") this.deviceClasses.agua = true;
              if (element.name === "energía") this.deviceClasses.energia = true;
              if (element.name === "gas") this.deviceClasses.gas = true;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onUploadLogo(url) {
      this.downloadURL = url;
    },
  },
};
</script>
