var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-8 px-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openNewUser()}}},[_c('v-icon',[_vm._v("fa-plus")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.last_session",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLastSession(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Acciones ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openUpdateUser(item)}}},[_vm._v(" Modificar ")]),(!_vm.isSuperAdmin)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.listarN2(item)}}},[_vm._v(" Listar N2 ")]):_vm._e(),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Eliminar ")])]}}],null,true),model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Seguro de eliminar este usuario? ")]),_c('v-card-text',[_vm._v("Una vez eliminado este usuario, no podrá recuperarlo. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialogConfirm = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","text":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" Confirmo ")])],1)],1)],1)],1)],[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Cambiar contraseña ")])]}}],null,true),model:{value:(_vm.dialogConfirmPassword),callback:function ($$v) {_vm.dialogConfirmPassword=$$v},expression:"dialogConfirmPassword"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Esta seguro de cambiar contraseña? ")]),_c('v-card-text',[_vm._v("Se va a generar una nueva contraseña aleatoria segura para el usuario. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialogConfirmPassword = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","text":""},on:{"click":function($event){return _vm.changePassword(item)}}},[_vm._v(" Confirmo ")])],1)],1)],1)],1)],[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_vm._v(" Iniciar sesión ")])]}}],null,true),model:{value:(_vm.dialogConfirmLoginById),callback:function ($$v) {_vm.dialogConfirmLoginById=$$v},expression:"dialogConfirmLoginById"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Esta seguro de iniciar sesión? ")]),_c('v-card-text',[_vm._v("Va a ingresar a la cuenta del usuario seleccionado ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialogConfirmLoginById = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","text":"","loading":_vm.loadingLoginById},on:{"click":function($event){return _vm.loginById(item)}}},[_vm._v(" Confirmo ")])],1)],1)],1)],1)]],2)],1)]}}],null,true)})],1)],1),_c('NewUser',{ref:"newUserComponent",on:{"newUser":_vm.onCreatedUser}}),_c('DialogPasswordComponent',{ref:"dialogResetPassworh",on:{"onOk":_vm.onResetPassword}}),_c('div',{staticClass:"text-center ma-2"},[_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" No se pudo realizar esta accion ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }